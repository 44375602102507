import React from 'react'
import './SellerOrdersFilterModal.css'

const SellerOrdersFilterModal = ({children, onClose}) => {
    return (
        <div  className="modal-overlay">
            <div className="modal-content">
                <button className="close-btn" onClick={onClose}>X</button>
                {children} {/* This will render the content passed to the Modal */}
            </div>
        </div>
    )
}

export default SellerOrdersFilterModal
