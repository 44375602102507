import React from 'react'
import './ProdMgmt.css'

const ProdMgmt = () => {
    return (
        <div>
            <h1>Product Management</h1>
            <p>Coming Soon!</p>
        </div>
    )
}

export default ProdMgmt
