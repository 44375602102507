import React from 'react'
import './SellerOrderDetails.css'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const SellerOrderDetails = () => {
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const { orderId } = useParams();

    const API_URL = '/data.json';

    // Fetch order details from API
    useEffect(() => {
        const fetchOrderDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get(API_URL);
                const data = response.data.orders;

                // filter the order by id
                const orderDetails = data.filter((order) => order.id === parseInt(orderId, 10));
                setOrder(orderDetails[0]);

            } catch (error) {
                console.error('Error fetching order details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrderDetails();
    }, [orderId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!order) {
        return <p>Order not found.</p>;
    }


    return (
        <div className="order-details-container">
            <div>
                <h1>Order Details</h1>
            </div>
            <div className='order-details-wrapper'>
                <div className='details-container'>
                    <div className='order-date-number'>
                        <p>{order.orderNumber}</p>
                        <p>{new Date(order.date).toLocaleDateString()}</p>
                    </div>
                    <div className='details-status'>
                        <p>Order Status: {order.status}</p>
                        <p>Payment Status: {order.paymentStatus}</p>
                    </div>
                    {/* First Row: Product Details */}
                    <div className="product-details">
                        <img src={order.productImage} alt={order.productName} className="product-image" />
                        <div className="details-product-info">
                            <h2>{order.productName}</h2>
                            <div className='product-info-price'>
                                <p>Unit Price: GH₵{order.unitPrice}</p>
                                <p>Quantity: {order.quantity}</p>
                                <p>Total Price: GH₵{order.total}</p>
                            </div>
                        </div>
                    </div>

                    {/* Second Row: Delivery & Order Information */}
                    <div className="delivery-order-info">
                        {/* Left Column: Delivery Information */}
                        <div className="delivery-info">
                            <h3>Delivery Information</h3>
                            <p>Estimated Delivery Date: {order.estimatedDeliveryDate}</p>
                            <p>Delivery Method: {order.deliveryMethod}</p>
                        </div>

                        {/* Right Column: Order Information */}
                        <div className="order-info">
                        <h3>Order Information</h3>
                        <p>Total Amount: GH₵{order.total}</p>
                        </div>
                    </div>

                    {/* Third Row: Payment Information */}
                    <div className="payment-info">
                        <h3>Payment Information</h3>
                        <p>Payment Method: {order.paymentMethod}</p>
                        <div className="payment-details">
                            <p>Item Price: GH₵{order.unitPrice}</p>
                            <p>Delivery Fee: GH₵{order.deliveryFee}</p>
                            <p>Total Amount: GH₵{order.total}</p>
                        </div>
                    </div>
                </div>
                <div className='customer-info'>
                    <h2>Customer</h2>
                    <div>
                        <p>Buyer's Name: {order.buyerName}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellerOrderDetails
