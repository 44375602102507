import React, { useState } from 'react'
import './HeroNav.css'
import { RiHome6Line } from "react-icons/ri";
import { FaRegCompass } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import storeIcon from "../../assets/icons/hugeicons_store-01.svg"

const HeroNav = () => {
    const [selectedButton, setSelectedButton] = useState(false);

    // handle button click
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };


    return (
        <div className='heroNavContainer' >
            <Link to={'/'} className={`homeButton ${selectedButton ==='home' ? 'changeColor' : ''}`} onClick={() => handleButtonClick('home')}>
                <RiHome6Line size='1.4rem' className={`homeIcon ${selectedButton ==='home'? 'changeColor' : ''}`} />
                Home
            </Link>
            <Link to={'/discoveries'} className={`discoverButton ${selectedButton ==='discovery' ? 'changeColor' : ''}`} onClick={() => handleButtonClick('discovery')}>
                <FaRegCompass size='1.4rem' className={`discoverIcon ${selectedButton ==='discovery'? 'changeColor' : ''}`} />
                Discover
            </Link>
            <Link to={'/my-feed'} className={`feedButton ${selectedButton ==='feed' ? 'changeColor' : ''}`} onClick={() => handleButtonClick('feed')}>
                <img src={storeIcon} alt="store icon" className={`storeIcon ${selectedButton ==='feed'? 'changeColor' : ''}`} />
                Feed
            </Link>
        </div>
    )
}

export default HeroNav
