import React, { useEffect } from 'react'
import './ProfilePage.css'
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom'
import Logo from '../../assets/Footer Logo.svg'


const ProfilePage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // get data from local storage
    const userInfo = localStorage.getItem('userInfo');
    const userInfoParsed = JSON.parse(userInfo);
    // get user role information from parsed data
    const userRole = userInfoParsed.userRole;

    // Redirect to Overview by default if no sub-route is selected
    useEffect(() => {
        if (userRole === 'buyer' && location.pathname === '/profile') {
            navigate('/profile/overview');
        }
        if (userRole === 'seller' && location.pathname === '/profile') {
            navigate('/profile/seller-overview');
        }
    }, [location, navigate, userRole]);

    
    // handle logout
    const handleLogout = () => {
        localStorage.removeItem('userRole');
        navigate('/')
    }


    return (
        <div className={`profilePage ${userRole === 'seller'? 'sellerProfilePage' : ''}`}>
            {userRole === 'buyer' && ( // render if logged user is a buyer
                <div className='profilePage-content'>
                    <div className="nav-column">
                        <ul>
                            <div>
                                <li><NavLink to="/profile/overview" className={({ isActive }) => isActive ? 'active' : ''}>Overview</NavLink></li>
                            </div>
                            <hr/>
                            <div>
                                <li><NavLink to="/profile/orders" className={({ isActive }) => isActive ? 'active' : ''}>Orders</NavLink></li>
                                <li><NavLink to="/profile/messages" className={({ isActive }) => isActive ? 'active' : ''}>Messages</NavLink></li>
                            </div>
                            <hr/>
                            <div>
                                <li><NavLink to="/profile/wishlist" className={({ isActive }) => isActive ? 'active' : ''}>Wishlist</NavLink></li>
                                <li><NavLink to="/profile/reviews" className={({ isActive }) => isActive ? 'active' : ''}>Review Purchases</NavLink></li>
                            </div>
                            <hr/>
                            <div>
                                <li><NavLink to="/profile/help" className={({ isActive }) => isActive ? 'active' : ''}>Help</NavLink></li>
                                <li><NavLink to="/profile/suggestions" className={({ isActive }) => isActive ? 'active' : ''}>Suggestions</NavLink></li>
                                <li><NavLink to="/profile/settings" className={({ isActive }) => isActive ? 'active' : ''}>Settings</NavLink></li>
                            </div>
                            <hr />
                        </ul>
                        <button className="logout-button" onClick={handleLogout}>Logout</button>
                    </div>
                
                    <div className="content-column">
                        <Outlet /> {/* This is where the selected component (e.g. Overview, Orders) will render */}
                    </div>
                </div>
            )}
            {userRole === 'seller' && (  //render if logged in user role is seller
                <div className='profilePage-content'>
                    <div className="nav-column">
                        <div onClick={() => navigate('/')} className='sideNavLogo'>
                            <img src={Logo} alt="logo" />
                        </div>
                        <ul>
                            <div>
                                <li><NavLink to="/profile/seller-overview" className={({ isActive }) => isActive ? 'active' : ''}>My Shop</NavLink></li>
                            </div>
                            <hr/>
                            <div>
                                <li><NavLink to="/profile/seller-orders" className={({ isActive }) => isActive ? 'active' : ''}>Orders</NavLink></li>
                                <li><NavLink to="/profile/product-mgmt" className={({ isActive }) => isActive ? 'active' : ''}>Product Management</NavLink></li>
                            </div>
                            <hr/>
                            <div>
                                <li><NavLink to="/profile/messages" className={({ isActive }) => isActive ? 'active' : ''}>Messages</NavLink></li>
                                <li><NavLink to="/profile/customers" className={({ isActive }) => isActive ? 'active' : ''}>Customers</NavLink></li>
                                <li><NavLink to="/profile/wallet" className={({ isActive }) => isActive ? 'active' : ''}>Wallet</NavLink></li>
                                <li><NavLink to="/profile/analytics" className={({ isActive }) => isActive ? 'active' : ''}>Analytics</NavLink></li>
                            </div>
                            <hr/>
                            <div>
                                <li><NavLink to="/profile/help" className={({ isActive }) => isActive ? 'active' : ''}>Help Center</NavLink></li>
                                <li><NavLink to="/profile/settings" className={({ isActive }) => isActive ? 'active' : ''}>Settings</NavLink></li>
                            </div>
                            <hr />
                        </ul>
                        <button className="logout-button" onClick={handleLogout}>Logout</button>
                    </div>
                    
                    <div className="seller-content-column">
                        <Outlet /> {/* This is where the selected component (e.g. Overview, Orders) will render */}
                    </div>
                </div>
            )}  
        </div>
    )
}

export default ProfilePage
