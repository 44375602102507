import React from 'react'
import './Nav.css'
import { HiOutlineHeart } from "react-icons/hi"
import { FiShoppingCart } from "react-icons/fi"
import { PiBellBold } from "react-icons/pi";
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <div className='navBar'>
            <Link to={'./cart'}>
                <FiShoppingCart size='1.5rem' color='var(--globalbasewhite)'/>
            </Link>
            <Link to={'./wishlist'}>
                <HiOutlineHeart size='1.5rem' color='var(--globalbasewhite)'/>
            </Link>
            <Link to={'./notifications'}>
                <PiBellBold size='1.5rem' color='var(--globalbasewhite)'/>
            </Link>
            <Link to={'./auth'}>
                <button>Sign In</button>
            </Link>
        </div>
    )
}

export default Nav
