import React from 'react'
import './Header.css'
import Search from '../../components/search/Search'
import Nav from '../../components/navBar/Nav'
import logo from '../../assets/Logo-white.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Header = () => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getSearchProducts = async () => {
        try {
            const response = await fetch('/data.json');
            const data = await response.json();

            setProducts(data);
            if(Array.isArray(data.products)) {
            setProducts(data.products);
            } else {
            setError('Unexpected data format');
            }
        } catch (error) {
            console.error('Error fetching search products:', error);
        }
        };
        getSearchProducts();
    }, []);

    if(!products) {
        return <div>
        {error && 
            <p>No result matches your search term</p>
        }
        </div>
    }
    return (
        <div className='header'>
            <div className='itemsContainer'>
                <Link to={'/'} className='logo'>
                    <img src={logo} alt='logo' />
                </Link>
                <Search products={products} />
                <Nav />
            </div> 
        </div>
    )
}

export default Header
