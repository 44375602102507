import React, { useState } from 'react'
import './SignupForm.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'

const BASE_URL = 'https://new-kokorko-backend-da79e2d7d8a3.herokuapp.com/api'

const SignupForm = ({role}) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [apiErrors, setApiErrors] = useState({ email: '', password: '', confirmPassword: '', general: '' }); //store api errors
    const [loading, setLoading] = useState(false); // track loading state

    const onSubmit = async (data) => {
        setLoading(true);
        setApiErrors({ email: '', password: '', confirmPassword: '', general: '' });
        if (data.password!== data.confirmPassword) {
            setApiErrors({...apiErrors, confirmPassword: 'Passwords do not match' });
            return;
        }
        //remove confirmPassword from data
        delete data.confirmPassword;

        //add role to data and send as payload to server
        data.role = role;
        console.log('payload:', data);
        
        try {
            const response = await axios.post(`${BASE_URL}/signup/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                }});
                if (response.status === 201) {
                    window.location.href = '/login';
                } else {
                    throw new Error('Failed to sign up');
                }

            console.log('response:', response.data);

        } catch (error) {
            // console.log('error:', error.response.data);
            setApiErrors({...apiErrors, ...error.response.data });

        } finally {
            setLoading(false);
        }
    }

    // const validatePassword = (value) => {
    //     const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    //     return regex.test(value) || 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character';
    // }

    if(loading) return (<div>Loading...</div>)

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3>Sign up as {role.charAt(0).toUpperCase() + role.slice(1)}</h3>
            <div>
                <label>Email</label>
                <input
                    {...register('email', { required: 'Email is required' })}
                    type="email"
                    placeholder="Enter your email"
                />
                {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div>
                <label>Password</label>
                <input
                    {...register('password', { required: 'Password is required' })}
                    type="password"
                    placeholder="Enter your password"
                />
                {errors.password && <p>{errors.password.message}</p>}
            </div>
            <div>
                <label>Confirm Password</label>
                <input
                    {...register('confirmPassword', {
                        validate: (value) => value === watch('password') || 'Passwords do not match',
                    })}
                    type="password"
                    placeholder="Confirm your password"
                />
                {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
            </div>
            <button type="submit">Continue</button>
        </form>
    )
}

export default SignupForm
