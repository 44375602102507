import React from 'react'
import './CheckoutPage.css'
import TruckIcon from '../../assets/icons/truck.svg'
import ArrowRightIcon from '../../assets/icons/chevron-right.svg'
import { MdOutlineInfo } from "react-icons/md"
import VbuyIcon from '../../assets/icons/vbuy-icon.png'


const CheckoutPage = () => {
    return (
        <div className='checkout-page'>
            <h1>Complete Your Order</h1>
            <div className='checkout-steps'>
                <div className='checkout-step'>
                    <div className='checkout-step-tail'>
                        <p>Delivery Information</p>
                    </div>
                </div>
                <div className='checkout-step'>
                    <div className='checkout-step-tail'>
                        <p>Payment Information</p>
                    </div>
                </div>
                <div className='checkout-step'>
                    <div className='checkout-step-tail'>
                        <p>Review Order</p>
                    </div>
                </div>
            </div>
            <div className='checkout-main-container'>
                <form className='checkout-form-container'>
                    <div className='name-fields'>
                        <div className='form-group'>
                            <label>First Name</label>
                            <input type="text" placeholder='John' />
                        </div>
                        <div className='form-group'>
                            <label>Last Name</label>
                            <input type="text" placeholder='Doe' />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label>Address</label>
                        <input type="text" placeholder='Enter full address' />
                    </div>
                    <div className='form-group'>
                        <label>City</label>
                        <input type="text" placeholder='Enter city' />
                    </div>
                    <div className='form-group'>
                        <label>Region</label>
                        <select>
                            <option value=''>Select region</option>
                            <option value=''>Greater Accra Region</option>
                            <option value=''>Ashante Region</option>
                            <option value=''>Central Region</option>
                            <option value=''>Eastern Region</option>
                            <option value=''>Volta Region</option>
                            <option value=''>Western Region</option>
                            <option value=''>Western North Region</option>
                            <option value=''>Upper East Region</option>
                            <option value=''>Upper West Region</option>
                            <option value=''>Northern Region</option>
                            <option value=''>Savannah Region</option>
                            <option value=''>Bono Region</option>
                            <option value=''>Ahafo Region</option>
                            <option value=''>Bono East Region</option>
                            <option value=''>Oti Region</option>
                            <option value=''>Savannah Region</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Phone Number</label>
                        <input type="text" placeholder='Enter phone number' />
                    </div>
                </form>
                <div className='checkout-summary-container'>
                    <div className='delivery-method'>
                        <img src={TruckIcon} alt='truck icon' />
                        <p>Delivery Method</p>
                    </div>
                    <div className='delivery-options'>
                        <div className='checkout-door-option'>
                            <label>Door Delivery</label>
                            <div className='door-option-price'>
                                <p>GH₵ 30.00</p>
                                <input type="radio" name='delivery-option' />
                            </div>
                        </div>
                        <div className='checkout-pickup-option'>
                            <label>Pick Up</label>
                            <input type="radio" name='delivery-option' />
                        </div>
                    </div>
                    <div className='checkout-order-summary'>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-title'>Summary</p>
                            <p className='checkoutOrder-summary-titleDetails'>See details
                                <img src={ArrowRightIcon} alt='arrow right icon' />
                            </p>
                        </div>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-label'>Products</p>
                            <p className='checkoutOrder-summary-amount'>GH₵ 24,000.00</p>
                        </div>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-label'>Delivery</p>
                            <p className='checkoutOrder-summary-amount'>GH₵ 30.00</p>
                        </div>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-label'>Sub Total</p>
                            <p className='checkoutOrder-summary-total'>GH₵ 24,030.00</p>
                        </div>
                        <button className='checkoutOrder-summary-button'>Continue to Payment</button>
                    </div>
                    <div className='vbuy-payment-info'>
                        <MdOutlineInfo size='1rem'/>
                        <p>Secure payment via </p>
                        <img src={VbuyIcon} alt='Vbuy Icon' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage
