import React from 'react'
import './Wallet.css'

const Wallet = () => {
    return (
        <div>
            <h1>Wallet</h1>
            <p>Wallet</p>
        </div>
    )
}

export default Wallet
