import React from 'react'
import './SellerOverview.css'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { PiBellBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom'

const SellerOverview = () => {
    const [seller, setSeller] = useState(null);
    const [recentOrders, setRecentOrders] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [store, setStore] = useState(null);
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // get data from local storage
    const userInfo = localStorage.getItem('userInfo');
    const userInfoParsed = JSON.parse(userInfo);
    // get logged in user id from local storage
    const loggedInUserId = userInfoParsed.id;

    useEffect(() => {
        const fetchSellerData = async () => {
            try {
                const response = await axios.get('/data.json');
                const users = response.data.users;
                const stores = response.data.stores;
                console.log('users:', users);
                console.log('stores:', stores);

                // Find the logged-in seller's details
                const loggedInSeller = users.find(user => user.id === loggedInUserId && user.role === 'seller');
                if (!loggedInSeller) {
                    console.error('No seller found');
                    return;
                }
                setSeller(loggedInSeller);
                console.log('logged in seller:', loggedInSeller);

                // Find the seller's store using the store ID
                const sellerStore = stores.find(store => store.id === loggedInSeller.store);
                if (!sellerStore) {
                    console.error('No store found for this seller');
                    return;
                }
                setStore(sellerStore);
                console.log('seller store:', sellerStore);

                // Fetch recent orders for the store (Assumed mocked data for now)
                const ordersResponse = response.data.orders
                setRecentOrders(ordersResponse);
                
            } catch (error) {
                console.error('Error fetching seller data:', error);
            }
        };

        fetchSellerData();
    }, [loggedInUserId]);

    if (!seller || !store) {
        return <div>Loading...</div>;
    }

    // function to handle "View More" button click
    const handleViewMore = () => {
        setShowMore(!showMore);
    };

    // generate initials for seller or shop name
    const generateInitials = (name) => {
        const names = name.split(' ');
        const initials = names[0][0] + (names[1] ? names[1][0] : '');
        return initials.toUpperCase();
    };

    return (
        <div className="seller-overview-container">
            {/* Page Header */}
            <div className="header-row">
                <h1>My Shop</h1>
                <div className="header-icons">
                    <PiBellBold className="notification-icon" />
                    {seller.image ? (
                        <img src={seller.image} alt="Seller Avatar" className="avatar" />
                    ) : (
                        <div className="avatar">{generateInitials(seller.name)}</div>
                    )}
                </div>
            </div>

            <div className='seller-overview-content'>
                {/* Banner Row */}
                <div className="banner-row">
                    {store.banner ? (
                        <img src={store.banner} alt="Shop Banner" className="banner-image" />
                    ) : (
                        <div className="banner-placeholder">
                            <h2>{store.name}</h2>
                        </div>
                    )}
                </div>

                {/* Shop Details */}
                <div className="shop-details-row">
                    <div className="shop-logo">
                        {store.logo ? (
                            <img src={store.logo} alt="Shop Logo" className="logo-image" />
                        ) : (
                            <div className="logo-placeholder">{generateInitials(store.name)}</div>
                        )}
                    </div>
                    <div className="shop-info">
                        <div className='shop-name'>
                            <h3>{store.name}</h3>
                            <p className='seller-shop-followers'>{store.followers || 0} followers</p> 
                        </div>
                        <p className='seller-shop-category'>{store.category || 'Category Not specified'}</p> 
                        <p className="seller-shop-description">{store.description || 'No description available'}</p>
                    </div>
                </div>

                {/* Shop Metrics */}
                <div className='metrics-section'>
                    <h3>Your Metrics for Today</h3>
                    <div className="metrics-row">
                        <div className="metric revenue">
                            <h4>Total Revenue</h4>
                            <p>GH₵{store.totalRevenue || 0}</p> 
                        </div>
                        <div className="metric sales">
                            <h4>Total Sales Today</h4>
                            <p>GH₵{store.totalSalesToday || 0}</p> 
                        </div>
                    </div> 
                </div>

                {/* Recent Orders */}
                <div className="recent-orders-row">
                    <h4>Recent Orders</h4>
                    {recentOrders.slice(0, showMore ? recentOrders.length : 3).map((order) => (
                        <div key={order.id} className="order-item">
                            <img src={order.productImage} alt={order.productName} className="product-image" />
                            <div className="order-details">
                                <div>
                                    <p className='order-details-name'>{order.productName}</p>
                                    <div className='order-details-price'>
                                        <p className='price'>GH₵{order.unitPrice}</p>
                                        <p className='quantity'>X{order.quantity}</p>
                                    </div>
                                </div>
                                <div className='order-details-date'>
                                    <p>Date: {new Date(order.date).toLocaleDateString()}</p>
                                    <p>{new Date(order.date).toLocaleTimeString()}</p>
                                    <button className="details-btn" onClick={() => navigate(`/profile/seller-orders/${order.id}`)}>View Details</button>
                                </div>
                            </div>
                        </div>
                    ))}
                    {!showMore && recentOrders.length > 3 && (
                        <button className="view-more-btn" onClick={handleViewMore}>View More</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SellerOverview
