import React from 'react'
import './Feed.css'
import ShopIcon from '../../assets/icons/store-03-stroke-rounded.svg'
import HeroNav from '../../components/heroNav/HeroNav'
import FeedMugs from '../../assets/images/feed-images/feed-mugs.jpeg'
import FeedGlasses from '../../assets/images/feed-images/feed-glases.jpeg'
import FeedGlassesSingle from '../../assets/images/feed-images/feed-glases-single.jpeg'
import FeedPlates from '../../assets/images/feed-images/feed-plates.jpeg'
import FeedCookWare from '../../assets/images/feed-images/feed-cookware.jpeg'
import FeedTube from '../../assets/images/feed-images/feed-tube.jpeg'
import FeedBodyOil from '../../assets/images/feed-images/feed-body-oil.jpeg'
import FeedLotion from '../../assets/images/feed-images/feed-lotion.jpeg'
import FeedBathOil from '../../assets/images/feed-images/feed-bath-oil.jpeg'
import FeedWhiteBag from '../../assets/images/feed-images/feed-white-bag.jpeg'
import FeedWatch  from '../../assets/images/feed-images/feed-watch.jpeg'
import FeedBlackBag from '../../assets/images/feed-images/feed-black-bag.jpeg'
import FeedDarkGlasses from '../../assets/images/feed-images/feed-dark-glasses.jpeg'
import FeedIncense from '../../assets/images/feed-images/feed-incense.jpeg'
import FeedPerfume from '../../assets/images/feed-images/feed-perfume.jpeg'
import FeedBowls from '../../assets/images/feed-images/feed-bowls.jpeg'
import FeedGlassware from '../../assets/images/feed-images/feed-glassware.jpeg'
import FeedTennisBat from '../../assets/images/feed-images/feed-tennis-bat.jpeg'
import FeedDarkPerfume from '../../assets/images/feed-images/feed-dark-perfume.jpeg'
import FeedNike from '../../assets/images/feed-images/feed-nike.jpeg'
import FeedClosset from '../../assets/images/feed-images/feed-closset.jpeg'
import FeedFootball from '../../assets/images/feed-images/feed-football.jpeg'
import FeedSneakers from '../../assets/images/feed-images/feed-sneakers.jpeg'
import FeedBoots from '../../assets/images/feed-images/feed-boots.jpeg'
import { IoMdHeartEmpty } from "react-icons/io";

const Feed = () => {
    return (
        <div className='feed-page'>
            <div className='feed-topNav'>
                <HeroNav />
            </div>
            <div className='feed-container'>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedPlates} alt='feed-plates' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedGlasses} alt='feed-glasses' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedGlassesSingle} alt='feed-glasses-single' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedMugs} alt='feed-mugs' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
            </div>
            <div className='feed-container'>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedCookWare} alt='feed-cookware' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedLotion} alt='feed-lotion' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedBodyOil} alt='feed-body-oil' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedTube} alt='feed-tube' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
            </div>
            <div className='feed-container'>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedBathOil} alt='feed-bath-oil' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedWhiteBag} alt='feed-white-bag' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedWatch} alt='feed-watch'/>
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedBlackBag} alt='feed-black-bag' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
            </div>
            <div className='feed-container'>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedDarkGlasses} alt='feed-dark-glasses' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedIncense} alt='feed-incense' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedPerfume} alt='feed-perfume' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedBowls} alt='feed-bowls' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
            </div>
            <div className='feed-container'>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedGlassware} alt='feed-glassware' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedTennisBat} alt='feed-tennis-bat' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedDarkPerfume} alt='feed-dark-perfume' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedNike} alt='feed-nike' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
            </div>
            <div className='feed-container'>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedClosset} alt='feed-closset' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedFootball} alt='feed-football' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedBoots} alt='feed-boots' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
                <div className='feed-item'>
                    <div className='feed-item-shop'>
                        <h1>Gadget Galaxy</h1>
                        <img src={ShopIcon} alt='shop-icon' />
                    </div>
                    <div className='feed-item-img'>
                        <img src={FeedSneakers} alt='feed-sneakers' />
                    </div>
                    <div className='feed-item-text'>
                        <p className='feed-item-name'>Sound Audio nest</p>
                        <p className='feed-item-price'>GH₵20,000.00</p>
                        <div className='feed-item-button'>Buy Now</div>
                    </div>
                </div>
                <div className='feed-item-heart-icon'>
                    <IoMdHeartEmpty size='1.5rem'/>
                </div>
            </div>
        </div>
    )
}

export default Feed
