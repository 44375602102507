import React from 'react'
import './Customers.css'

const Customers = () => {
    return (
        <div>
            <h1>Customers</h1>
            <p>Customers Page</p>
        </div>
    )
}

export default Customers
