import React, { useEffect, useState } from 'react'
import './SellerOrders.css'
import SellerOrdersFilterModal from '../sellerOrdersFilterModal/SellerOrdersFilterModal';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const SellerOrders = () => {
    const [ orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [statusCounts, setStatusCounts] = useState({
        pending: 0,
        inTransit: 0,
        delivered: 0,
        cancelled: 0,
        all: 0
    });
    const [selectedDateFilter, setSelectedDateFilter] = useState('');  // Store selected date filter (last 7 days, last 30 days, custom)
    const [paymentFilters, setPaymentFilters] = useState({
        inEscrow: false,
        released: false
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [ordersPerPage] = useState(4);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [customDateRange, setCustomDateRange] = useState([null, null]); // Store custom date range
    const [startDate, endDate] = customDateRange; // Destructure custom date range
    

    // fetch orders from api
    const API_URL = '/data.json';

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            try {
                const response = await axios.get(API_URL);
                const data = response.data.orders;

                // Set orders and count order statuses
                setOrders(data);
                setFilteredOrders(data); // Initially, no filters applied

                // Update the counts for each status
                const statusCounts = {
                    pending: data.filter(order => order.status === 'pending').length,
                    inTransit: data.filter(order => order.status === 'in transit').length,
                    delivered: data.filter(order => order.status === 'delivered').length,
                    cancelled: data.filter(order => order.status === 'cancelled').length,
                    all: data.length,
                };

                setStatusCounts(statusCounts);

            } catch (error) {
                setError('Failed to load orders. Please try again.', error);
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);

    // handle status filter changes
    const handleStatusFilterChange = (status) => {
        if (status === 'all') {
            setFilteredOrders(orders);
        } else {
            const filtered = orders.filter(order => order.status === status);
            setFilteredOrders(filtered);
        }
    };

    // handle search term changes
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
        const filtered = orders.filter(order =>
            order.productName.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredOrders(filtered);
    };

    // Handle payment filter change (checkbox)
    const handlePaymentFilterChange = (filterName) => {
        setPaymentFilters((prev) => ({
            ...prev,
            [filterName]: !prev[filterName],
        }));
    };

    // handle filter modal open/close
    const toggleFilterModal = () => {
        setFilterModalOpen(!filterModalOpen);
    }

    // apply filters from modal
    const applyFilters = () => {
        let filtered = orders;

        // filter by data range
        const now = new Date();
        if (selectedDateFilter === 'last7days') {
            const last7days = new Date();
            last7days.setDate(now.getDate() - 7);
            filtered = filtered.filter(order => new Date(order.date) >= last7days);
        } else if (selectedDateFilter === 'last30days') {
            const last30days = new Date();
            last30days.setDate(now.getDate() - 30);
            filtered = filtered.filter(order => new Date(order.date) >= last30days);
        } else if (selectedDateFilter === 'custom' && startDate && endDate) {
            filtered = filtered.filter(order => {
                const orderDate = new Date(order.date);
                return orderDate >= startDate && orderDate <= endDate;
            });
        }


        // filter by payment status
        if (paymentFilters.inEscrow) {
            filtered = filtered.filter(order => order.paymentStatus === 'in escrow');
        } else if (paymentFilters.released) {
            filtered = filtered.filter(order => order.paymentStatus === 'released');
        }

        setFilteredOrders(filtered);
        toggleFilterModal();
    }


    // pagination logic
    // const indexOfLastOrder = currentPage * ordersPerPage;
    // const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    // const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    // const paginate = pageNumber => setCurrentPage(pageNumber);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="orders-page">
            {/* Order Status Summary */}
            <div className="order-status-summary">
                <div className="status-container">
                    <p>Total Orders</p>
                    <p>{statusCounts.all}</p>
                </div>
                <div className="status-container">
                    <p>Pending</p> 
                    <p>{statusCounts.pending}</p>
                </div>
                <div className="status-container">
                    <p>In Transit</p> 
                    <p>{statusCounts.inTransit}</p>
                </div>
                <div className="status-container">
                    <p>Delivered</p> 
                    <p>{statusCounts.delivered}</p>
                </div>
                <div className="status-container">
                    <p>Cancelled</p> 
                    <p>{statusCounts.cancelled}</p>
                </div>
            </div>

            {/* Search and Filter Row */}
            <div className="search-filter-row">
                <input
                    type="text"
                    placeholder="Search orders"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="search-bar"
                />
                <button className="filter-btn" onClick={toggleFilterModal}>Filter</button>
            </div>

            {/* Order Status Filter Row */}
            <div className="status-filter-row">
                <button onClick={() => handleStatusFilterChange('all')}>All</button>
                <button onClick={() => handleStatusFilterChange('pending')}>Pending</button>
                <button onClick={() => handleStatusFilterChange('in transit')}>In Transit</button>
                <button onClick={() => handleStatusFilterChange('delivered')}>Delivered</button>
                <button onClick={() => handleStatusFilterChange('cancelled')}>Cancelled</button>
            </div>

            {/* Orders Table */}
            <table className="orders-table">
                <thead>
                    <tr>
                        <th><input type="checkbox" /></th> {/* Checkbox for selecting all */}
                        <th>Order ID</th>
                        <th>Product Name</th>
                        <th>Date</th>
                        <th>Order Quantity</th>
                        <th>Ordered By</th>
                        <th>Order Status</th>
                        <th>Payment Status</th>
                        <th>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders.map(order => (
                        <tr key={order.id}>
                            <td><input type="checkbox" /></td>
                            <td>{order.id}</td>
                            <td>
                                <a href={`/profile/seller-orders/${order.id}`} className="order-link">
                                    {order.productName}
                                </a>
                            </td>
                            <td>{new Date(order.date).toLocaleDateString()}</td>
                            <td>{order.quantity}</td>
                            <td>{order.orderedBy}</td>
                            <td>{order.status}</td>
                            <td>{order.paymentStatus}</td>
                            <td>GH₵{order.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination if needed */}
            {/* Pagination logic can be added here */}
            
            {/* Filter Modal */}
            {filterModalOpen && (
                <SellerOrdersFilterModal onClose={toggleFilterModal}>
                    <div className="filter-modal-content">
                        {/* Date filter radio buttons */}
                        <div className="radio-group">
                            <p>Date</p>
                            <label>
                                Last 7 Days
                                <input
                                    type="radio"
                                    name="dateFilter"
                                    value="last7days"
                                    checked={selectedDateFilter === 'last7days'}
                                    onChange={(e) => setSelectedDateFilter(e.target.value)}
                                />
                            </label>
                            <label>
                                Last 30 Days
                                <input
                                    type="radio"
                                    name="dateFilter"
                                    value="last30days"
                                    checked={selectedDateFilter === 'last30days'}
                                    onChange={(e) => setSelectedDateFilter(e.target.value)}
                                />
                            </label>
                            <label>
                                Set Custom Date
                                <input
                                    type="radio"
                                    name="dateFilter"
                                    value="custom"
                                    checked={selectedDateFilter === 'custom'}
                                    onChange={(e) => setSelectedDateFilter(e.target.value)}
                                />
                            </label>
                            {selectedDateFilter === 'custom' && (
                                <DatePicker
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => setCustomDateRange(update)}
                                    isClearable={true}
                                    placeholderText="Select custom date range"
                                />
                            )}
                        </div>

                        {/* Payment status filter checkboxes */}
                        <div className="checkbox-group">
                            <p>Payment Status</p>
                            <label>
                                In Escrow
                                <input
                                    type="checkbox"
                                    checked={paymentFilters.inEscrow}
                                    onChange={() => handlePaymentFilterChange('inEscrow')}
                                />
                            </label>
                            <label>
                                Released
                                <input
                                    type="checkbox"
                                    checked={paymentFilters.released}
                                    onChange={() => handlePaymentFilterChange('released')}
                                />
                            </label>
                        </div>

                        {/* Apply Filters */}
                        <button onClick={applyFilters}>Apply Filters</button>
                    </div>
                </SellerOrdersFilterModal>
            )}
        </div>
    );
}

export default SellerOrders
