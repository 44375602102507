import React, { useState, useEffect } from 'react'
import './Orders.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Orders = () => {
    const [ orders, setOrders] = useState([]);
    const [statusFilter, setStatusFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage] = useState(4);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // fetch orders from api
    const API_URL = '/data.json';

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            try {
                const response = await axios.get(API_URL);
                // const data = await response.data;

                setOrders(response.data.orders);

            } catch (error) {
                setError('Failed to load orders. Please try again.', error);
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);

    // filter orders by status
    const filteredOrders = orders.filter(order => 
        statusFilter === 'all' || order.status === statusFilter
    );

    // handle order click
    const handleOrderClick = (orderId) => {
        // Navigate to the order details page with the selected order ID
        navigate(`/profile/orders/${orderId}`);
    };

    // pagination logic
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="orders-container">
            {/* Filter Pane */}
            <div className="filter-pane">
                <button onClick={() => setStatusFilter('all')}>All</button>
                <button onClick={() => setStatusFilter('pending')}>Pending</button>
                <button onClick={() => setStatusFilter('processing')}>Processing</button>
                <button onClick={() => setStatusFilter('in transit')}>In Transit</button>
                <button onClick={() => setStatusFilter('delivered')}>Delivered</button>
                <button onClick={() => setStatusFilter('cancelled')}>Cancelled</button>
                <button onClick={() => setStatusFilter('returns & refund')}>Returns & Refund</button>
            </div>

            {/* Order List */}
            <div className="order-list">
                {currentOrders.map(order => (
                <div key={order.id} className="order-item">
                    <div className="order-details">
                        <img src={order.productImage} alt={order.productName} className="product-image" />
                        <div className="order-info">
                            <div>
                                <h4>{order.productName}</h4>
                                <p>Unit Price: GH₵{order.unitPrice}</p>
                                <p>Quantity: {order.quantity}</p>
                                <p>Total: GH₵{order.total}</p>
                            </div>
                            <div>
                                <p>Order No: {order.orderNumber}</p>
                                <p>Date: {new Date(order.date).toLocaleDateString()}</p>
                                <p>Status: {order.status}</p>
                            </div>
                        </div>
                    </div>
                    <button className="details-btn" onClick={() => handleOrderClick(order.id)}>View Details</button>
                </div>
                ))}
            </div>

            {/* Pagination */}
            <div className="pagination">
                {Array.from({ length: Math.ceil(filteredOrders.length / ordersPerPage) }, (_, i) => (
                <button key={i + 1} onClick={() => paginate(i + 1)}
                    className={currentPage === i + 1 ? 'active' : ''}
                >
                    {i + 1}
                </button>
                ))}
            </div>
        </div>
    )
}

export default Orders
