import React, {useState, useEffect} from 'react'
import './TopPicks.css'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ProductCard from '../../components/productsCard/ProductCard'

const TopPicks = () => {
    const[products, setProducts] = useState([]);
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    // get specific products
    const topPicksProductOne = [14];
    const topPicksProductTwo =  [15];
    const topPicksProductThree = [16];
    const topPicksProductFour = [17];
    const topPicksProductFive = [18];

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = response.data.products;
                
                if(Array.isArray(data) && data.length > 0) {
                    setProducts(data);
                    console.log(data)
                }
                
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        fetchProducts();
    }, []);

    // filter products
    const topPicksOne = products.filter((product) => topPicksProductOne.includes(product.id));
    const topPicksTwo = products.filter((product) => topPicksProductTwo.includes(product.id));
    const topPicksThree = products.filter((product) => topPicksProductThree.includes(product.id));
    const topPicksFour = products.filter((product) => topPicksProductFour.includes(product.id));
    const topPicksFive = products.filter((product) => topPicksProductFive.includes(product.id));

    if(loading) {
        return <h1>Loading...</h1>
    }

    if(!products) {
        return (
            <div>
                {error &&
                    <p>Sorry, an error occured loading data. Please reload page.</p>
                }
            </div>
        )
    } else return (
        <div className='topPicks'>
            <div className='topPicks-header'>
                <h1>Top Picks for you</h1>
                <Link to={'./top-picks'}><ViewAllButton /></Link>
            </div>
            <div className='topPicks-container'>
                <div className='topPicks-productOne'>
                    {topPicksOne.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='topPicks-productTwo'>
                    {topPicksTwo.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='topPicks-productThree'>
                    {topPicksThree.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='topPicks-productFour'>
                    {topPicksFour.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='topPicks-productFive'>
                    {topPicksFive.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TopPicks
