import React from 'react'
import './ProductDetails.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import LeftArrowImage from '../../assets/icons/chevron-left.svg'
import RightArrowImage from '../../assets/icons/chevron-right.svg'
import HeroTop from '../../containers/heroTop/HeroTop'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import BestSellers from '../../containers/bestSeller/BestSeller'
import TruckIcon from '../../assets/icons/truck.svg'
import { MdOutlineInfo } from "react-icons/md"
import VbuyIcon from '../../assets/icons/vbuy-icon.png'
import { FaStar, FaStarHalf, FaRegStar } from "react-icons/fa"
import FacebookIcon from '../../assets/icons/facebook.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import InstagramIcon from '../../assets/icons/instagram.svg'
import SnapchatIcon from '../../assets/icons/snapchat.svg'
import TiktokIcon from '../../assets/icons/tiktok.svg'
import WhatsappIcon from '../../assets/icons/whatsapp.svg'
import { HiOutlineHeart } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'



const ProductDetails = () => {
    const[product, setProduct] = useState({ image:[], productRating:[] });
    const[loading, setLoading] = useState(false);
    const[selectedImage, setSelectedImage] = useState('');
    const[ratingList, setRatingList] = useState([]);
    const[visibleReviews, setVisibleReviews] = useState(2);
    const[store, setStore] = useState([]);
    const {id} = useParams();
    const[error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const getProductDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = response.data.products;

                // get list of stores
                const sellers = response.data.stores
                // console.log('sellers:', sellers);
                if (Array.isArray(sellers) && sellers.length > 0) {
                    const foundStore = sellers.find(store => store.id === product.seller);
                    // console.log('found store:', foundStore);
                    
                    if(foundStore) {
                        setStore(foundStore);
                    }
                }

                if (Array.isArray(data) && data.length > 0) {
                    const foundProduct = data.find(p => p.id === parseInt(id))
                    
                    if(foundProduct) {
                    setProduct(foundProduct);
                    setSelectedImage(foundProduct.image[0]);
                    setRatingList(foundProduct.productRating);
                    // console.log(ratingList);
                    
                    } else {
                        setError('Product not found');
                    }
                } else {
                    setError('Unexpected data format');
                }
            } catch (e) {
                setError('Error loading data');
            }
            setLoading(false);
        };
        getProductDetails();
    }, [id, product.seller])

    const loadMoreReviews = () => {
        setVisibleReviews(prev => prev + 2);
    };

    if(loading) {
        return <div>Loading...</div>
    }

    if (!product || error) {
        return (
            <div>
                { error &&
                    <p>Error loading page. Please refresh.</p>
                }
            </div>
        )
    }

    const handleIconClick = (e, url) => {
        e.stopPropagation();
        navigate(url);
    }
    
    return (
        <div className='product-details-page'>
            <div>
                <HeroTop />
            </div>
            <div>
                <Breadcrumb />
            </div>
            <div className='product-details-container'>
                <div className='top-container'>
                    <div className='top-container-items'>
                        <div className='product-image'>
                            <img src={selectedImage} alt={product.name} />
                            <div className='productDetails-heart-icon' onClick={(e) => handleIconClick(e, `/wishlist/add/${id}`)}>
                                <HiOutlineHeart size='1.3rem' />
                            </div>
                        </div>
                        <div className='productDetails-info-container'>
                            <div className='product-info-store'>
                                <img src={store.logo} alt='store logo'/>
                                <div className='product-info-store-text'>
                                    <p className='product-info-store-name'>{store.name}</p>
                                    <p className='product-info-store-follow'>Following</p>
                                </div>
                            </div>
                            <h1 className='product-info-name'>
                                {product.name}
                            </h1>
                            <div className="product-rating">
                                {/* find the average rating and display it in the value out of 5 stars */}
                                <div className="product-rating-stars">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <span key={index}>
                                            {product.productRating.length > 0 ? (
                                                product.productRating.reduce((acc, rating) => acc + rating.rating, 0) / product.productRating.length >= index + 1 ? (
                                                    index < Math.floor(product.productRating.reduce((acc, rating) => acc + rating.rating, 0) / product.productRating.length) ? (
                                                        <FaStar key={index} color="#FF5D37" />
                                                    ) : (
                                                        <FaStarHalf key={index} color="#FF5D37" />
                                                    )
                                                ) : (
                                                    <FaRegStar key={index} color="#FF5D37" />
                                                )
                                            ) : (
                                                <FaRegStar key={index} color="#FF5D37" />
                                            )}
                                        </span>
                                    ))}
                                </div>

                                <p className="product-rating-count">{product.productRating.length} ratings</p>
                            </div>
                            <div className='product-info-priceNdiscount'>
                                <p className='product-info-discount'>GH₵ {(product.discount || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                <div className='product-info-discount-percentage-container'>
                                    <p className='product-info-discount-percentage'>-{product.price ? ((product.discount || 0)/(product.price) * 100).toFixed(0) : 0}%</p>
                                    <p className='product-info-price'>GH₵ {(product.price || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                </div>
                            </div>
                            <span className='line-separater'></span>
                            <div className='product-info-quantity'>
                                <p>Quantity:</p>
                                <div className='quantity-buttons-container'>
                                    <button className='quantity-minus-button'>-</button>
                                    <span>1</span>
                                    <button className='quantity-plus-button'>+</button>
                                </div>
                            </div>
                            <div className='product-info-color'>
                                <p>Color</p>
                                <div className='color-buttons-container'>
                                    <div className='color-button'></div>
                                    <div className='color-button'></div>
                                    <div className='color-button'></div>
                                    <div className='color-button'></div>
                                    <div className='color-button'></div>
                                    <div className='color-button'></div>
                                    <div className='color-button'></div>
                                </div>
                            </div>
                        </div>
                        <div className='productDetails-checkoutInfo-container'>
                            <div className='productDetails-checkout-info'>
                                <h2>
                                    <img src={TruckIcon} alt='truck icon' />
                                    Delivery Information:
                                </h2>
                                <div className='productDetails-delivery-info'>
                                    <p>Free delivery within Accra</p>
                                </div>
                                <div>
                                    <p>Outside Accra: <b>GH₵ 30.00</b></p>
                                </div>
                                <div>
                                    <p>Standard delivery in 2-3 business days</p>
                                </div>
                                <span className='line-separater'></span>
                                <div>
                                    <p className='in-stock-title'>In stock:</p>
                                    <p className='in-stock-text'>Only 5 left in stock – order now!</p>
                                </div>
                                <span className='line-separater'></span>
                                <div className='productDetails-checkout-buttons'>
                                    <button className='productDetails-cart-button'>Add to Cart</button>
                                    <button className='productDetails-buy-button'>Buy Now</button>
                                </div>
                            </div>
                            <div className='productDetails-payment-info'>
                                <MdOutlineInfo size='1rem'/>
                                <p>Secure payment via </p>
                                <img src={VbuyIcon} alt='Vbuy Icon' />
                            </div>
                        </div>
                    </div>
                    <div className='thumbnails-social-container'>
                        <div className='productImage-thumbnail-container'>
                            {product.image && product.image.length > 0 && product.image.map((image, index) => (
                                <div key={index} className='productImage-thumbnail'>
                                    <img src={image} alt={product.name} onClick={() => setSelectedImage(image)} />
                                </div>
                            ))}
                            <div className='productImage-thumbnail-controls'>
                                <div className='thumbnail-controls-container'>
                                    <img src={LeftArrowImage} alt='Left Arrow' />
                                </div>
                                <div className='thumbnail-controls-container'>
                                    <img src={RightArrowImage} alt='Right Arrow' />
                                </div>
                            </div>
                        </div>
                        <div className='social-share-container'>
                            <p>Share this product</p>
                            <div className='social-share-icons'>
                                <img src={FacebookIcon} alt='facebook' />
                                <img src={TwitterIcon} alt='twitter' />
                                <img src={SnapchatIcon} alt='snapchat' />
                                <img src={TiktokIcon} alt='tiktok' />
                                <img src={InstagramIcon} alt='instagram' />
                                <img src={WhatsappIcon} alt='whatsapp' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='productDetails-bottom-container'>
                    <div className='productDetails-titles'>
                        <h2>Product Description</h2>
                        <p>{product.description}</p>
                    </div>
                    <span className='line-separater'></span>
                    <div className='productDetails-titles'>
                        <h2>Specifications</h2>
                        {product.specifications && product.specifications.length > 0 && (
                            <div>
                                {product.specifications.map((spec, index) => (
                                    <p key={index} className='specification-item'>
                                        <span className='specification-name'>{spec.name}{spec.name !== '' && ':'}</span>
                                        <span className='specification-value'>{spec.value}</span>
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                    <span className='line-separater'></span>
                    <div className='productDetails-titles'>
                        <h2>Customer Reviews</h2>
                        <div className='customer-reviews-container'>
                            {
                            ratingList && ratingList.slice(0, visibleReviews).map((rating, index) => (
                                <div key={index} className='customer-review-container'>
                                    <div className='customer-review-info'>
                                        <div className='customer-review-name'>
                                            <p>{rating.user}</p>
                                        </div>
                                        <div className='customer-review-rating'>
                                            <p>
                                                {
                                                    [...Array(5)].map((_, index) => {
                                                        const ratingValue = rating.rating 
                                                        if (index + 1 <= Math.floor(ratingValue)) {
                                                        return <FaStar key={index} color="#FF5D37" />;
                                                        } else if (index + 0.5 <= ratingValue) {
                                                        return <FaStarHalf key={index} color="#FF5D37" />;
                                                        } else {
                                                        return <FaRegStar key={index} color="#FF5D37" />;
                                                        }
                                                    })
                                                }
                                            </p>
                                            <p>({rating.date})</p>
                                        </div>
                                    </div>
                                    <div className='customer-review-review'>
                                        <p>{rating.review}</p>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                        {ratingList && visibleReviews < ratingList.length && (
                            <div className='view-more-container'>
                                <button onClick={loadMoreReviews} className='view-more-button'>
                                    View More
                                </button>
                            </div>
                        )}
                    </div>
                    <span className='line-separater'></span>
                </div>
                <div className='productDetails-titles product-similar'>
                    <div className='product-similar-title'>
                        <h2>Similar Products</h2>
                        <h2>View All</h2>
                    </div>
                    <div className='product-similar-container'>
                        <BestSellers />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProductDetails
