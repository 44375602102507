import React, {useEffect, useState} from 'react'
import './FlashDeals.css'
import { Link } from 'react-router-dom'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import axios from 'axios'
import ProductCard from '../../components/productsCard/ProductCard'

const FlashDeals = () => {
    const[products, setProducts] = useState([]);
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    
    // get specific products
    const flashDealsProductOne = [7];
    const flashDealsProductTwo =  [8];
    const flashDealsProductThree = [9];
    const flashDealsProductFour = [12];
    const flashDealsProductFive = [13];
    
    // fetch products
    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = response.data.products;
                
                if(Array.isArray(data) && data.length > 0) {
                    setProducts(data);
                }
                
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        fetchProducts();
    }, []);

    // filter products
    const flashDealsOne = products.filter((product) => flashDealsProductOne.includes(product.id));
    const flashDealsTwo = products.filter((product) => flashDealsProductTwo.includes(product.id));
    const flashDealsThree = products.filter((product) => flashDealsProductThree.includes(product.id));
    const flashDealsFour = products.filter((product) => flashDealsProductFour.includes(product.id));
    const flashDealsFive = products.filter((product) => flashDealsProductFive.includes(product.id));
    

    if(loading) {
        return <h1>Loading...</h1>
    }

    if(!products) {
        return (
            <div>
                {error &&
                    <p>Sorry, an error occured loading data. Please reload page.</p>
                }
            </div>
        )
    } else return (
        <div className='flashDeals'>
            <div className='flashDeals-header'>
                <h1>Flash Deals</h1>
                <Link to={'./flash-deals'}><ViewAllButton /></Link>
            </div>
            <div className='flashDeals-container'>
            <div className='flashDeals-productOne'>
                    {flashDealsOne.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='flashDeals-productTwo'>
                    {flashDealsTwo.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='flashDeals-productThree'>
                    {flashDealsThree.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='flashDeals-productFour'>
                    {flashDealsFour.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
                <div className='flashDeals-productFive'>
                    {flashDealsFive.map((product) => {
                        return (
                            <ProductCard 
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false} 
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false} 
                                showCategory={false} 
                                showSeller={false} 
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className='product-page-card-home'
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FlashDeals
