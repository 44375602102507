import React, { useState, useEffect } from 'react'
import './DiscoveriesPage.css'
import axios from 'axios'
import { Link } from'react-router-dom'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import ProductCard from '../../components/productsCard/ProductCard'
import HeroNav from '../../components/heroNav/HeroNav'
import BeuatyImage from '../../assets/images/bento-images/beauty.png'
import GiftIdeasImage from '../../assets/images/bento-images/gift-ideas.png'
import InfluencerImage from '../../assets/images/bento-images/influencer-collab.png'
import TrendingImage from '../../assets/images/bento-images/trending.png'
import FragranceImage from '../../assets/images/bento-images/fragrance.png'
import JourneyImage from '../../assets/images/bento-images/journey.png'
import GameChangerImage from '../../assets/images/bento-images/game-changer.png'
import BedImage from '../../assets/images/bento-images/bed.png'
import UtensilsImage from '../../assets/images/bento-images/utensils.png'
import BlueWhiteJacketImage from '../../assets/images/bento-images/blue-white-jacket.png'
import BentoEarringsImage from '../../assets/images/bento-images/bento-ear-rings.png'
import BentoNecklaceImage from '../../assets/images/bento-images/bento-necklace.png'

const DiscoveriesPage = () => {
    const[products, setProducts] = useState([]);
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    // get specific products
    const seasonalPicksProductOne = [28];
    const seasonalPicksProductTwo =  [29];
    const seasonalPicksProductThree = [30];
    const seasonalPicksProductFour = [31];
    const seasonalPicksProductFive = [32];
    // customer favorites
    const customerPicksProductOne = [33];
    const customerPicksProductTwo =  [34];
    const customerPicksProductThree = [35];
    const customerPicksProductFour = [36];
    const customerPicksProductFive = [37];
    // tech essentials
    const techPicksProductOne = [38];
    const techPicksProductTwo =  [39];
    const techPicksProductThree = [40];
    const techPicksProductFour = [41];
    const techPicksProductFive = [42];


    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = response.data.products;
                
                setProducts(data);
                
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        fetchProducts();
    }, []);

    // filter products
    const seasonalPicksOne = products.filter((product) => seasonalPicksProductOne.includes(product.id));
    const seasonalPicksTwo = products.filter((product) => seasonalPicksProductTwo.includes(product.id));
    const seasonalPicksThree = products.filter((product) => seasonalPicksProductThree.includes(product.id));
    const seasonalPicksFour = products.filter((product) => seasonalPicksProductFour.includes(product.id));
    const seasonalPicksFive = products.filter((product) => seasonalPicksProductFive.includes(product.id));

    const customerPicksOne = products.filter((product) => customerPicksProductOne.includes(product.id));
    const customerPicksTwo = products.filter((product) => customerPicksProductTwo.includes(product.id));
    const customerPicksThree = products.filter((product) => customerPicksProductThree.includes(product.id));
    const customerPicksFour = products.filter((product) => customerPicksProductFour.includes(product.id));
    const customerPicksFive = products.filter((product) => customerPicksProductFive.includes(product.id));

    const techPicksOne = products.filter((product) => techPicksProductOne.includes(product.id));
    const techPicksTwo = products.filter((product) => techPicksProductTwo.includes(product.id));
    const techPicksThree = products.filter((product) => techPicksProductThree.includes(product.id));
    const techPicksFour = products.filter((product) => techPicksProductFour.includes(product.id));
    const techPicksFive = products.filter((product) => techPicksProductFive.includes(product.id));

    if(loading) {
        return <h1>Loading...</h1>
    }

    if(!products) {
        return (
            <div>
                {error &&
                    <p>Sorry, an error occured loading data. Please reload page.</p>
                }
            </div>
        )
    } else return (
        <div className='discoveries-page'>
            <div className='discoveries-page-top'>
                <HeroNav />
            </div>
            <div className='discoveries-page-bottom'>
                <div className='bento-top'>
                    <div className='bento-top-top'>
                        <div className='bento-top-top-left'>
                            <h2>Influencer Collabs</h2>
                            <img src={InfluencerImage} alt='Influencer Collabs' />
                        </div>
                        <div className='bento-top-top-middle'>
                            <div className='bento-top-top-middle-top'>
                                <h2>Gift Ideas</h2>
                                <img src={GiftIdeasImage} alt='Gift Ideas' />
                            </div>
                            <div className='bento-top-top-middle-bottom'>
                                <h2>Transform Your Beauty</h2>
                                <img src={BeuatyImage} alt='Beauty' />
                            </div>
                        </div>
                        <div className='bento-top-top-right'>
                            <h2>Trending</h2>
                            <img src={TrendingImage} alt='Trending' />
                        </div>
                    </div>
                    <div className='bento-top-bottom'>
                        <div className='bento-top-bottom-left'>
                            <h2>Game Changer Gear</h2>
                            <img src={GameChangerImage} alt='Game Changer' />
                        </div>
                        <div className='bento-top-bottom-middle'>
                            <h2>Your Journey, Elevated</h2>
                            <img src={JourneyImage} alt='Journey' />
                        </div>
                        <div className='bento-top-bottom-right'>
                            <h2>Fragrance Finds</h2>
                            <img src={FragranceImage} alt='Fragrance' />
                        </div>
                    </div>
                </div>
                <div className='discoveriesPage-divider'></div>
                <div className='seasonal-picks'>
                    <div className='seasonalPicks-header'>
                        <h1>Seasonal Picks</h1>
                        <Link to={'./top-picks'}><ViewAllButton /></Link>
                    </div>
                    <div className='seasonal-picks-container'>
                        <div className='seasonalPicks-productOne'>
                            {seasonalPicksOne.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productTwo'>
                            {seasonalPicksTwo.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productThree'>
                            {seasonalPicksThree.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productFour'>
                            {seasonalPicksFour.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productFive'>
                            {seasonalPicksFive.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='customer-favourites'>
                <div className='seasonalPicks-header'>
                        <h1>Customer Favourites</h1>
                        <Link to={'./top-picks'}><ViewAllButton /></Link>
                    </div>
                    <div className='seasonal-picks-container'>
                        <div className='seasonalPicks-productOne'>
                            {customerPicksOne.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productTwo'>
                            {customerPicksTwo.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productThree'>
                            {customerPicksThree.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productFour'>
                            {customerPicksFour.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productFive'>
                            {customerPicksFive.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='discoveriesPage-divider'></div>
                <div className='bento-bottom'>
                    <div className='bento-bottom-left'>
                        <h2>Discover Your Style</h2>
                        <img src={BlueWhiteJacketImage} alt='jacket' />
                    </div>
                    <div className='bento-bottom-middle'>
                        <div className='bento-bottom-middle-top'>
                            <h2>Upgrade Your Kitchen</h2>
                            <img src={UtensilsImage} alt='utensils' />
                        </div>
                        <div className='bento-bottom-middle-bottom'>
                            <h2>New Arrivals</h2>
                            <div className='bento-bottom-middle-bottom-images'>
                                <img className='earRing-img' src={BentoEarringsImage} alt='ear rings' />
                                <img className='necklace-img' src={BentoNecklaceImage} alt='necklace'/>
                            </div>
                        </div>
                    </div>
                    <div className='bento-bottom-right'>
                        <h2>Home Sweet Home</h2>
                        <img src={BedImage} alt='bed' />
                    </div>
                </div>
                <div className='discoveriesPage-divider'></div>
                <div className='tech-essentials'>
                <div className='seasonalPicks-header'>
                        <h1>Tech Essentials</h1>
                        <Link to={'./top-picks'}><ViewAllButton /></Link>
                    </div>
                    <div className='seasonal-picks-container'>
                        <div className='seasonalPicks-productOne'>
                            {techPicksOne.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productTwo'>
                            {techPicksTwo.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productThree'>
                            {techPicksThree.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productFour'>
                            {techPicksFour.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                        <div className='seasonalPicks-productFive'>
                            {techPicksFive.map((product) => {
                                return (
                                    <ProductCard 
                                        key={product.id}
                                        id={product.id}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        discount={product.discount}
                                        image={product.image[0]}
                                        category={product.category}
                                        rating={product.rating}
                                        reviews={product.reviews}
                                        showName={true}
                                        showDescription={false}
                                        showPrice={true}
                                        showDiscount={false} 
                                        showDiscountPercentage={false}
                                        showRating={false}
                                        showReview={false} 
                                        showCategory={false} 
                                        showSeller={false} 
                                        showButton={false}
                                        showCartIcon={true}
                                        showHeartIcon={true}
                                        className='product-page-card-home'
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiscoveriesPage