import React from 'react'
import './Analytics.css'

const Analytics = () => {
    return (
        <div>
            <h1>Analytics</h1>
            <p>Analytics</p>
        </div>
    )
}

export default Analytics
