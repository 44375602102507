import React from 'react'
import './CartPage.css'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import TrashIcon from '../../assets/icons/trash-01.svg'
import PhoneImage from '../../assets/images/phone.jpeg'
import { MdOutlineInfo } from "react-icons/md"
import HeroTop from '../../containers/heroTop/HeroTop'
import VbuyIcon from '../../assets/icons/vbuy-icon.png'
import BestSellers from '../../containers/bestSeller/BestSeller'
import { Link } from 'react-router-dom'

const CartPage = () => {
    return (
        <div className='cart-page-container'>
            <HeroTop />
            <Breadcrumb />
            <div className='cart-page-details'>
                <div className='cart-page-items'>
                    <div className='cart-page-item'>
                        <div className='cart-page-item-img'>
                            <img src={PhoneImage} alt='phone' />
                        </div>
                        <div className='cart-page-item-info'>
                            <div className='cart-page-itemInfo-left'>
                                <div>
                                    <h1 className='cart-page-item-name'>Sumsung S22 Ultra</h1>
                                    <p className='cart-page-item-color'>Black</p>
                                    <p className='cart-page-item-price'>GH₵12,000.00</p>
                                </div>
                                <div className='cart-page-item-quantity'>
                                    <button>-</button>
                                    <p>2</p>
                                    <button>+</button>
                                </div>
                            </div>
                            <div className='cart-page-itemInfo-right'>
                                <img src={TrashIcon} alt='trash-icon' />
                                <p>Sub total: <span>GHS 24,000.00</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cart-page-checkout-section'>
                    <div className='cart-page-total'>
                        <h2>Cart Summary</h2>
                        <div className='cart-page-delivery'>
                            <MdOutlineInfo size='1.5rem' color='#222222'/>
                            <p>Delivery fee not included yet</p>
                        </div>
                        <div className='cartPage-products-total'>
                            <p className='cartPage-products-title'>Products</p>
                            <p className='cartPage-products-price'>GH₵24,000.00</p>
                        </div>
                        <div className='cartPage-sub-total'>
                            <p className='cartPage-products-title'>Sub total</p>
                            <p className='cartPage-sub-price'>GH₵24,000.00</p>
                        </div>
                        <Link to='/checkout' className='cart-page-checkout'>
                            Checkout   
                        </Link>
                    </div>
                    <div className='cart-payment-info'>
                        <MdOutlineInfo size='1rem'/>
                        <p>Secure payment via </p>
                        <img src={VbuyIcon} alt='Vbuy Icon' />
                    </div>
                </div>
            </div>
            <div className='cart-page-similarProducts'>
                <div className='product-similar-title'>
                    <h2>Similar Products</h2>
                    <p>View All</p>
                </div>
                <div className='product-similar-container'>
                    <BestSellers />
                </div>
            </div>
        </div>
    )
}

export default CartPage
